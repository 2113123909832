import React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";

import { NavLink} from "react-router-dom";

import { grey } from '@mui/material/colors';

import MenuIconRight from "../../Assets/Icons/Navigation-Right.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TocIcon from "@mui/icons-material/Toc";

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import styles from "./Navbar.module.css";
import Avatar from "../../Assets/Images/Mask Group 17.png";
import {
  SearchOutlinedIcon,
  ZoomOutMapOutlinedIcon,
  NotificationsOutlinedIcon,
  Logo2,
  MenuOutlinedIcon,
} from "../../Assets";
import { useWindowDimensions } from "../../Hooks";
import { AppSwitcher, BackDropCmp } from "..";
import { Link, useNavigate } from "react-router-dom";
import { toggleFullScreen } from "../../Utils/utils";
import { AvatarCmp } from "..";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfileAction } from "../../Store/Actions/ProfileAction";
import useAlert from "../../Hooks/useAlert/useAlert";
import { LoaderCmp } from "..";
import useGetUserRole from "../../Hooks/getUserRole";
import Button from "@mui/material/Button";
import { useSelect } from "@mui/base";
import { GetNotificationCount } from "../../Store/Actions/NotificationAction";
import { Badge } from "@mui/material";

const Navbar = ({
  sideBarClose,
  drawerWidth,
  handleDrawerToggle,
  handleSideBarToggle,
}) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const { setAlert } = useAlert();
  const { profileDetails, profileDetailsLoading } = useSelector(
    (state) => state.UserProfileReducer
  );

  const { userLogoutLoading } = useSelector((state) => state.UserLogoutReducer);

  const userRole = useGetUserRole();
  return width >= "768" ? (
    userRole === "manufacturer" ? (
      <ManuFacturerNavBar
        profileDetails={profileDetails}
        navigate={navigate}
        sideBarClose={sideBarClose}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        handleSideBarToggle={handleSideBarToggle}
        profileDetailsLoading={profileDetailsLoading}
      />
    ) :  userRole === "supplier" ? (
      <SupplierNavBar
        profileDetails={profileDetails}
        navigate={navigate}
        sideBarClose={sideBarClose}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        handleSideBarToggle={handleSideBarToggle}
        profileDetailsLoading={profileDetailsLoading}
      />
    ) : (
      <AppNavBar
        profileDetails={profileDetails}
        navigate={navigate}
        sideBarClose={sideBarClose}
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        handleSideBarToggle={handleSideBarToggle}
        profileDetailsLoading={profileDetailsLoading}
      />
    )
  ) : (
    <MobileNavBar
      sideBarClose={sideBarClose}
      drawerWidth={drawerWidth}
      handleDrawerToggle={handleDrawerToggle}
      handleSideBarToggle={handleSideBarToggle}
    />
  );
};

const AppNavBar = ({
  profileDetails,
  navigate,
  sideBarClose,
  drawerWidth,
  handleDrawerToggle,
  handleSideBarToggle,
  profileDetailsLoading,
}) => {
  const { notificationCountdata, notificationCountLoading } = useSelector(
    (state) => state.GetNotificationCountReducer
  );
  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  useEffect(() => {
    console.log("wapsi Q chala hai yheh");
    dispatch(GetNotificationCount(setAlert));
  }, []);


  const goToShop = () =>{
    const userData = JSON.parse(localStorage.getItem("userData"))
    const shopUserData =  {
      company_name : userData.company_name,
      all_roles: userData.all_roles,
      name: userData.name,
      email: userData.email,
      company_email: userData.company_email,
      token:userData.token,
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone: userData.phone,
      street_address: userData.street_address,
      zip: userData.zip
    }
  
    const str = `?data=${encodeURIComponent(JSON.stringify(shopUserData))}`
    window.location.href = `https://shop.buildlink.com/login${str}`
    
  } 


  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          lg: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
        },
        ml: { sm: `${drawerWidth}px` },
        background: "white",
      }}
    >
      <Toolbar sx={{ pr: "0 !important" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          {/* <MenuOpenIcon /> */}
          <img
            // onClick={() => handleDrawerToggle()}
            src={MenuIconRight}
            alt="icon"
          />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {!sideBarClose && (
            <IconButton
              // color="inherit"
              // aria-label="open drawer"
              // edge="start"
              onClick={handleSideBarToggle}
              sx={{
                display: { xs: "none", lg: "inline-block", marginRight: 12 },
              }}
            >
              <MenuOpenIcon />
              {/* <img src={MenuIconRight} alt="icon" /> */}
            </IconButton>
          )}
        </Typography>

        <section className={styles.navbarWrapper}>
          <div className={styles.navbarOptions}>
            <ul>
              <li
                onClick={() => {
                  navigate("/buildlinks/proconnection");
                }}
              >
                Pro Connections
              </li>
              <li
                onClick={() => {
                  navigate("/jobs");
                }}
              >
                Build Bid
              </li>
              <li
                onClick={() => {
                  navigate("/buildstudio/jobs");
                }}
              >
                Build Studio
              </li>
              <li
                onClick={() => {
                  navigate("/buildlinks/chat");
                }}
              >
                Chat
              </li>
            </ul>
          </div>
          <div className={styles.navOtherOptions}>
            <section className={styles.navIconWrapper}>
              {/* <div>
                <SearchOutlinedIcon fontSize={"small"} />
              </div> */}
              <div>
                <ZoomOutMapOutlinedIcon
                  onClick={() => {
                    toggleFullScreen();
                  }}
                  fontSize={"small"}
                />
              </div>
              <div onClick={() => navigate("/notification")}>
                {/* <NotificationsOutlinedIcon fontSize={"small"} /> */}
                <Badge
                  badgeContent={notificationCountdata || 0}
                  color="primary"
                >
                  <NotificationsOutlinedIcon
                    fontSize={"small"}
                    color="action"
                  />
                </Badge>
              </div>
            </section>
            <section className={styles.shoplink}>
              <a onClick={goToShop}>
                Go to Shop
              </a>
            </section>
            <section className={styles.navAvatar}>
              <div className={styles.navAvatarName}>
                {profileDetailsLoading ? (
                  <LoaderCmp />
                ) : (
                  <>
                    <p>{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
                    <small>{profileDetails?.type}</small>
                  </>
                )}
              </div>
              {/* <Link to="/buildlinks/profile">
                <img src={Avatar} alt="" />
              </Link> */}
              <AvatarCmp
                img={`${profileDetails?.ImgURL}${profileDetails?.image}`}
                firstLetter={profileDetails?.first_name}
              />
            </section>
            {/* <section className={styles.navProfile}>
              <TocIcon />
            </section> */}
          </div>
        </section>
      </Toolbar>
    </AppBar>
  );
};

const ManuFacturerNavBar = ({
  profileDetails,
  navigate,
  sideBarClose,
  drawerWidth,
  handleDrawerToggle,
  handleSideBarToggle,
  profileDetailsLoading,
}) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          lg: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
        },
        ml: { sm: `${drawerWidth}px` },
        background: "white",
      }}
    >
      <Toolbar sx={{ pr: "0 !important" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          {/* <MenuOpenIcon /> */}
          <img
            // onClick={() => handleDrawerToggle()}
            src={MenuIconRight}
            alt="icon"
          />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {!sideBarClose && (
            <IconButton
              onClick={handleSideBarToggle}
              sx={{
                display: { xs: "none", lg: "inline-block", marginRight: 12 },
              }}
            >
              <MenuOpenIcon />
              {/* <img src={MenuIconRight} alt="icon" /> */}
            </IconButton>
          )}
        </Typography>

        <section className={styles.navbarWrapper}>
          <div className={styles.navOtherOptions2}>
            <section className={styles.navIconWrapper}>
              <div>
                <ZoomOutMapOutlinedIcon
                  onClick={() => {
                    toggleFullScreen();
                  }}
                  fontSize={"small"}
                />
              </div>
              <div>
                <NotificationsOutlinedIcon fontSize={"small"} />
              </div>
            </section>
            <section className={styles.navAvatar}>
              <div className={styles.navAvatarName}>
                {profileDetailsLoading ? (
                  <LoaderCmp />
                ) : (
                  <>
                    <p>{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
                    <small>{profileDetails?.type}</small>
                  </>
                )}
              </div>
              <AvatarCmp
                img={`${profileDetails?.ImgURL}${profileDetails?.image}`}
                firstLetter={profileDetails?.first_name}
                profileLink="manufacturer/profile"
              />
            </section>
          </div>
        </section>
      </Toolbar>
    </AppBar>
  );
};

const SupplierNavBar = ({
  profileDetails,
  navigate,
  sideBarClose,
  drawerWidth,
  handleDrawerToggle,
  handleSideBarToggle,
  profileDetailsLoading,
}) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          lg: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
        },
        ml: { sm: `${drawerWidth}px` },
        background: "white",
      }}
    >
      <Toolbar sx={{ pr: "0 !important" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          {/* <MenuOpenIcon /> */}
          <img
            // onClick={() => handleDrawerToggle()}
            src={MenuIconRight}
            alt="icon"
          />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {!sideBarClose && (
            <IconButton
              onClick={handleSideBarToggle}
              sx={{
                display: { xs: "none", lg: "inline-block", marginRight: 12 },
              }}
            >
              <MenuOpenIcon />
              {/* <img src={MenuIconRight} alt="icon" /> */}
            </IconButton>
          )}
        </Typography>

        <section className={styles.navbarWrapper}>
          <div className={styles.navOtherOptions2}>
            <section className={styles.navIconWrapper}>
              <div>
                <ZoomOutMapOutlinedIcon
                  onClick={() => {
                    toggleFullScreen();
                  }}
                  fontSize={"small"}
                />
              </div>
              <div>
                <NotificationsOutlinedIcon fontSize={"small"} />
              </div>
            </section>
            <section className={styles.navAvatar}>
              <div className={styles.navAvatarName}>
                {profileDetailsLoading ? (
                  <LoaderCmp />
                ) : (
                  <>
                    <p>{`${profileDetails?.first_name} ${profileDetails?.last_name}`}</p>
                    <small>{profileDetails?.type}</small>
                  </>
                )}
              </div>
              <AvatarCmp
                img={`${profileDetails?.ImgURL}${profileDetails?.image}`}
                firstLetter={profileDetails?.first_name}
                profileLink="supplier/profile"
              />
            </section>
          </div>
        </section>
      </Toolbar>
    </AppBar>
  );
};

const MobileNavBar = ({
  sideBarClose,
  drawerWidth,
  handleDrawerToggle,
  handleSideBarToggle,
}) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: {
          lg: sideBarClose ? `calc(100% - ${drawerWidth}px)` : "100%",
        },
        ml: { sm: `${drawerWidth}px` },
        background: "white",
      }}
    >
      <Toolbar>
        <img src={Logo2} style={{ marginRight: "20px" }} alt="" />
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <AppsOutlinedIcon sx={{ color: "var(--textColor)" }} />
        </IconButton> */}
        <AppSwitcher />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuOutlinedIcon sx={{ color: "var(--textColor)" }} />
        </IconButton>

        <section className={styles.mobileNavbarWrapper}>
          {/* <div className={styles.navbarOptions}>
            <ul>
              <li>Pro Connections</li>
              <li>Build Bid</li>
              <li>Build Studios</li>
            </ul>
          </div> */}
          <div className={styles.navOtherOptions}>
            <section className={styles.navIconWrapper}>
              <div>
                <NotificationsOutlinedIcon fontSize={"small"} />
              </div>
            </section>
            <section className={styles.mobileNavAvatar}>
              <div className={styles.mobileNavAvatarName}>
                <p>Eliel Joel</p>
                <small>Pro Connection</small>
              </div>
              <img src={Avatar} alt="" />
            </section>
          </div>
        </section>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
