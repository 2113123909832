import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonCmp,
  InputCmp,
  DropDownCmp,
  WhiteButtonCmp,
  TextAreaCmp,
} from "..";
import { useForm } from "react-hook-form";
import styles from "./ProfileForms.module.css";
import { UpdateProfileAction } from "../../Store/Actions/ProfileAction";
import useAlert from "../../Hooks/useAlert/useAlert";
import { GetStateAction } from "../../Store/Actions/GetActions";

const DocsAndRegForm = ({ handleCancel, profileDetailsdata }) => {
  const {
    licensed_profession,
    licensed_number,
    fein_corporate_charter_no,
    bonded,
    general_liability_insurance,
    gli_policy,
    gli_max,
    worker_comp,
    wc_policy,
    wc_max,
    business_description,
    affiliations,
    certifications,
  } = profileDetailsdata;

  ///=>>>gli = general Liability Insurance
  const [gliStatus, setGliStatus] = useState(true);
  const [workersComp, setWorkersComp] = useState(true);

  const { setAlert } = useAlert();
  const dispatch = useDispatch();

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      licensedProfession: licensed_profession || "",
      licenseNumber: licensed_number || "",
      FEIN: fein_corporate_charter_no || "",
      bonded: bonded ? "YES" : "NO",
      generalLiabilityInsurance: general_liability_insurance ? "YES" : "NO",
      gliPolicy: gli_policy || "",
      gliPolicyMax: gli_max || "",
      workersComp: worker_comp ? "YES" : "NO",
      wcPolicy: wc_policy || "",
      wcPolicyMax: wc_max || "",
      businessDescription: business_description || "",
      affiliations: affiliations || "",
      certifications: certifications || "",
      multiStates: [],
    },
  });

  const gliState = watch("generalLiabilityInsurance");
  const workersCompState = watch("workersComp");

  useEffect(() => {
    if (gliState) {
      console.log("gliState", gliState);
      if (gliState === "NO") {
        setGliStatus(true);
        setValue("gliPolicy", "");
        setValue("gliPolicyMax", "");
      } else {
        setGliStatus(false);
      }
    }
  }, [gliState]);

  useEffect(() => {
    if (workersCompState) {
      if (workersCompState === "NO") {
        setWorkersComp(true);
        setValue("wcPolicy", "");
        setValue("wcPolicyMax", "");
      } else {
        setWorkersComp(false);
      }
    }
  }, [workersCompState]);

  useEffect(() => {
    dispatch(GetStateAction());
  }, []);
  const onSubmit = (obj) => {
    console.log(obj);
    dispatch(UpdateProfileAction(obj, "documentAndRegistration", setAlert));
  };

  const { statesdata, statesLoading } = useSelector(
    (state) => state.StatesReducers
  );
  return (
    <section className={styles.profile_doc_reg}>
      <div className={styles.profile_information_heading}>
        <Typography noWrap variant="h5" fontWeight={"bold"}>
          Documents and Registrations
        </Typography>
      </div>

      <div className={styles.detailsBoxes}>
        <section>
          <InputCmp
            name="licensedProfession" //imporant
            control={control} //imporant
            label="Licensed Profession"
          />
        </section>
        <section>
          <InputCmp
            name="licenseNumber" //imporant
            control={control} //imporant
            label="License Number #1"
          />
        </section>
        <section>
          {/* <InputCmp
            name="licenseNumber" //imporant
            control={control} //imporant
            label="Select States"
          /> */}
          <DropDownCmp
            control={control}
            name="multiStates"
            label={"State"}
            options={statesdata && statesdata}
            multiple={true}

            // disableClearable
            // freeSolo={false}
          />
        </section>
        <section>
          <InputCmp
            name="FEIN" //imporant
            control={control} //imporant
            label=" #, Corporate #, Charter #, or ASID #"
          />
        </section>

        {/* <section></section> */}
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <DropDownCmp
            name="bonded" //imporant
            control={control} //imporant
            label="Bonded"
            options={["YES", "NO"]}
            disableClearable
            freeSolo={false}
          />
        </section>
        <section>
          <DropDownCmp
            name="generalLiabilityInsurance" //imporant
            control={control} //imporant
            label="General Liability Insurance"
            options={["YES", "NO"]}
            disableClearable
            freeSolo={false}
          />

          <Box sx={{ mt: "15px" }}>
            <InputCmp
              name="gliPolicy" //imporant
              control={control} //imporant
              label="Policy #"
              disabled={gliStatus}
            />
          </Box>

          <Box sx={{ mt: "15px" }}>
            <InputCmp
              name="gliPolicyMax" //imporant
              control={control} //imporant
              label="Policy Maximum"
              disabled={gliStatus}
              type="number"
            />
          </Box>
        </section>
        <section>
          <DropDownCmp
            name="workersComp" //imporant
            control={control} //imporant
            label="Worker's Comp?"
            options={["YES", "NO"]}
            disableClearable
            freeSolo={false}
          />

          <Box sx={{ mt: "15px" }}>
            <InputCmp
              name="wcPolicy" //imporant
              control={control} //imporant
              label="Policy #"
              disabled={workersComp}
            />
          </Box>

          <Box sx={{ mt: "15px" }}>
            <InputCmp
              name="wcPolicyMax" //imporant
              control={control} //imporant
              label="Policy Maximum"
              disabled={workersComp}
              type="number"
            />
          </Box>
        </section>
        {/* <section></section> */}
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <TextAreaCmp
            control={control}
            name="businessDescription"
            label="Business description"
            rows={5}
          />
        </section>
      </div>
      <div className={styles.detailsBoxes}>
        <section>
          <TextAreaCmp
            control={control}
            name="affiliations"
            label="Affiliations"
            rows={5}
          />
        </section>
        <section>
          <TextAreaCmp
            control={control}
            name="certifications"
            label="Certifications"
            rows={5}
          />
        </section>
      </div>
      <div className={styles.buttonContainer}>
        <WhiteButtonCmp
          title="Cancel"
          onClick={() => handleCancel("documentsInfo")}
        />

        <ButtonCmp
          title="Save"
          style={{ marginLeft: "10px" }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </section>
  );
};

export default DocsAndRegForm;
