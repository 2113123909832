import { Routes, Route, Link } from "react-router-dom";
import React, { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material";

import {
	About,
	CreateJob,
	Home,
	Jobs,
	ProjectDetail,
	TrashJob,
	CompletedJob,
	JobRating,
	AwardedJob,
	AwardedJobDetail,
	MyBids,
	AwardedProject,
	AwardedBidDetail,
	WhishList,
	MyBidDetail,
	AppliedProjects,
	SubmitedBidDetails,
	CompletedProject,
	Profile,
	MyProjectGallery,
	TrashGallery,
	ProConnection,
	SearchProConnection,
	AddNewProject,
	ManufacturerViewProduct,
	AddManufacturerProduct,
	ImportProduct,
	ManufacturerTrashProduct,
	Chat,
	SingleBidDetails,
	UpdateJob,
	UpdateAddNewProject,
	PendingConnectionRequests,
	ManufacturerProfile,
	ReceviedConnectionRequests,
	Projects,
	JobsDetail,
	CreateNewProject,
	EditPDF,
	BSProjectTrash,
	EditBSProject,
	BidderRating,
	UserProfile,
	SupplierViewProduct,
	SupplierTrashProduct,
	SupplierAddProduct,
	SupplierProfile,
} from "../../Pages";

import { Layout } from "../../Components";
import BulkImport from "../../Pages/BulkImport/BulkImport";
import AddProductMain from "../../Pages/Product/AddProduct/ProductMain";
import EditProductMain from "../../Pages/Product/EditProduct/ProductMain";
import ViewProductDetails from "../../Pages/Product/ViewProductDetails/ViewProductDetails";
import OrderListing from "../../Pages/Order/OrderListing/OrderListing";
import OrderDetail from "../../Pages/Order/OrderDetail/OrderDetail";

const theme = createTheme({
	palette: {
		buttonColor: {
			// Purple and green play nicely together.
			main: "#005699",
			contrastText: "#fff",
		},
		successBtn: {
			// Purple and green play nicely together.
			main: "var(--green)",
			contrastText: "#fff",
		},
		buttonWhite: {
			// Purple and green play nicely together.
			main: "#fff",
			contrastText: "#111827",
			// color: "black !important",
			border: "1px solid black !important",
		},
	},
});

const AppRoutes = ({ profileDetails }) => {
	const { all_roles } = profileDetails || [];
	return (
		<ThemeProvider theme={theme}>
			<Layout>
				<Suspense fallback={<div>LOADING....</div>}>
					<Routes>
						{all_roles && all_roles.includes("Build Bid") && (
							<>
								<Route
									path="orders/detail/:orderId"
									element={<OrderDetail />}
								/>
								<Route
									path="orders/:status"
									element={<OrderListing />}
								/>
								<Route
									path="/dashboard"
									element={<Home />}
								/>
								<Route
									path="/about"
									element={<About />}
								/>
								<Route
									path="/jobs"
									element={<Jobs />}
								/>
								<Route
									path="/jobs/projectdetail/:slug"
									element={<ProjectDetail />}
								/>
								<Route
									path="/jobs/bid/:projectslug/:bidId"
									element={<SingleBidDetails />}
								/>
								<Route
									path="/createjob"
									element={<CreateJob />}
								/>
								<Route
									path="/updatejob/:projectSlug"
									element={<UpdateJob />}
								/>
								<Route
									path="/trashjob"
									element={<TrashJob />}
								/>
								<Route
									path="/completedjob"
									element={<CompletedJob />}
								/>
								<Route
									path="/completedjob/jobrating/:projectSlug"
									element={<JobRating />}
								/>
								<Route
									path="/awardedjob"
									element={<AwardedJob />}
								/>
								<Route
									path="/awardedjob/awardedjobdetail/:projetslug"
									element={<AwardedJobDetail />}
								/>
								<Route
									path="/mybids"
									element={<MyBids />}
								/>
								<Route
									path="/awardedproject"
									element={<AwardedProject />}
								/>
								<Route
									path="/awardedproject/awardedprojectdetails/:projetslug"
									element={<AwardedBidDetail />}
								/>
								<Route
									path="/whishlist"
									element={<WhishList />}
								/>
								<Route
									path="/mybids/biddetails/:slug"
									element={<MyBidDetail />}
								/>
								<Route
									path="/appliedprojects"
									element={<AppliedProjects />}
								/>
								<Route
									path="/appliedprojects/submitedbiddetails/:slug/:bidId"
									element={<SubmitedBidDetails />}
								/>
								<Route
									path="/completedproject"
									element={<CompletedProject />}
								/>
								<Route
									path="/completedproject/projectrating/:projectSlug"
									element={<BidderRating />}
								/>
								<Route path="/buildlinks">
									<Route
										path="profile"
										element={<Profile />}
									/>
									<Route
										path="userprofile/:useremail"
										element={<UserProfile />}
									/>
									<Route
										path="myprojectgallery"
										element={<MyProjectGallery />}
									/>
									<Route
										path="trashgallery"
										element={<TrashGallery />}
									/>
									<Route
										path="proconnection"
										element={<ProConnection />}
									/>
									<Route
										path="searchproconnection"
										element={<SearchProConnection />}
									/>
									<Route
										path="pendingrequests"
										element={<PendingConnectionRequests />}
									/>
									<Route
										path="receviedrequests"
										element={<ReceviedConnectionRequests />}
									/>
									<Route
										path="addnewproject"
										element={<AddNewProject />}
									/>
									<Route
										path="updatenewproject/:projectSlug"
										element={<UpdateAddNewProject />}
									/>
									<Route
										path="chat"
										element={<Chat />}
									/>
								</Route>
							</>
						)}
						{/* {all_roles && (all_roles.includes("Manufacturer") || all_roles.includes("Supplier")) && (
              <Route path="/product">
                <Route
                  path="viewproduct"
                  element={<ManufacturerViewProduct />}
                />
                                <Route
                  path="editproduct/:productSlug"
                  element={<EditProductMain />}
                />
                <Route
                  path="viewproduct/viewproductdetail/:productSlug"
                  element={<ViewProductDetails />}
                />
                <Route
                  path="addproduct"
                  element={<AddProductMain />}
                />
                <Route path="importproduct" element={<ImportProduct />} />
                <Route
                  path="trashedproducts"
                  element={<ManufacturerTrashProduct />}
                />
                <Route path="bulkimport" element={<BulkImport />} />
                <Route path="profile" element={<ManufacturerProfile />} />
                
              </Route>
             )}  */}
						{all_roles && all_roles.includes("Manufacturer") && (
							<Route path="/manufacturer">
								<Route
									path="orders/detail/:orderId"
									element={<OrderDetail />}
								/>
								<Route
									path="orders/:status"
									element={<OrderListing />}
								/>
								<Route
									path="viewproduct"
									element={<ManufacturerViewProduct />}
								/>
								<Route
									path="editproduct/:productSlug"
									element={<EditProductMain />}
								/>
								<Route
									path="viewproduct/viewproductdetail/:productSlug"
									element={<ViewProductDetails />}
								/>
								<Route
									path="addproduct"
									element={<AddProductMain />}
								/>
								<Route
									path="importproduct"
									element={<ImportProduct />}
								/>
								<Route
									path="trashedproducts"
									element={<ManufacturerTrashProduct />}
								/>
								<Route
									path="bulkimport"
									element={<BulkImport />}
								/>
								<Route
									path="profile"
									element={<ManufacturerProfile />}
								/>
							</Route>
						)}
						{all_roles && all_roles.includes("Supplier") && (
							<Route path="/supplier">
								<Route
									path="orders/detail/:orderId"
									element={<OrderDetail />}
								/>
								<Route
									path="orders/:status"
									element={<OrderListing />}
								/>
								<Route
									path="viewproduct"
									element={<SupplierViewProduct />}
								/>
								<Route
									path="editproduct/:productSlug"
									element={<EditProductMain />}
								/>
								<Route
									path="viewproduct/viewproductdetail/:productSlug"
									element={<ViewProductDetails />}
								/>
								<Route
									path="addproduct"
									element={<AddProductMain />}
								/>
								<Route
									path="importproduct"
									element={<ImportProduct />}
								/>
								<Route
									path="trashedproducts"
									element={<SupplierTrashProduct />}
								/>
								<Route
									path="bulkimport"
									element={<BulkImport />}
								/>
								<Route
									path="profile"
									element={<SupplierProfile />}
								/>
							</Route>
						)}

						{/* BUILD STUDIO */}
						{all_roles && all_roles.includes("Build Studio") && (
							<Route path="buildstudio">
								<Route
									path="orders/detail/:orderId"
									element={<OrderDetail />}
								/>
								<Route
									path="orders/:status"
									element={<OrderListing />}
								/>
								<Route
									path="jobs"
									element={<Projects />}
								/>
								<Route
									path="jobs/jobdetail/:projectID"
									element={<JobsDetail />}
								/>
								<Route
									path="createnewproject"
									element={<CreateNewProject />}
								/>
								<Route
									path="editpdf/:projectID"
									element={<EditPDF />}
								/>
								<Route
									path="trash"
									element={<BSProjectTrash />}
								/>
								<Route
									path="editbsproject/:projectSlug"
									element={<EditBSProject />}
								/>
							</Route>
						)}

						<Route
							path="*"
							element={<h1>You do not have permission for this Feature</h1>}
						/>
					</Routes>
				</Suspense>
			</Layout>
		</ThemeProvider>
	);
};

export default AppRoutes;
