import React from "react";
import styles from "./ManufacturerProfileCompletionForm.module.css";
import { Box, Grid, Typography } from "@mui/material";
import {
  BackDropCmp,
  ButtonCmp,
  DropDownCmp,
  InputCmp,
  WhiteButtonCmp,
} from "../../Components";
import { useForm } from "react-hook-form";
import {
  EmptyProfileWizardState,
  ProfileWizardAction,
} from "../../Store/Actions/ProfileAction";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCitiesAction,
  GetStateAction,
} from "../../Store/Actions/GetActions";
import { useEffect } from "react";
import useAlert from "../../Hooks/useAlert/useAlert";

const ProfileInfoForm = ({
  setWhichForm,
  setBarPercent,
  setprofileComplete,
}) => {
  const { setAlert } = useAlert();
  const dispatch = useDispatch();
  const { statesdata } = useSelector((state) => state.StatesReducers);
  const { citiesdata } = useSelector((state) => state.CitiesReducers);

  ///global states
  const { profileWizarddata } = useSelector(
    (state) => state.ProfielWizardReducer
  );

  const { control, handleSubmit, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      contactFirstName: "",
      contactLastName: "",
      companyName: "",
      companyStreetAddress: "",
      companyCity: "",
      companyState: "",
      companyZip: "",
      yearEst: "",
      companyPhone: "",
      companyEmailAddress: "",
      website: "",
    },
  });

  ///form state
  const stateState = watch("companyState", false);

  const onSubmit = (obj) => {
    dispatch(ProfileWizardAction(obj, "profileInformation", setAlert));
    // setWhichForm("docs&regs");
    // setBarPercent("25%");
    // setprofileComplete(true);
  };

  useEffect(() => {
    dispatch(GetStateAction());
  }, []);

  useEffect(() => {
    if (stateState) {
      dispatch(GetCitiesAction(stateState.label));
    }
  }, [stateState]);

  useEffect(() => {
    if (profileWizarddata) {
      setWhichForm("socialMedia");
      setBarPercent("65%");
      setprofileComplete(true);
      dispatch(EmptyProfileWizardState());
    }
  }, [profileWizarddata]);

  return (
    <section className={styles.profileInfo}>
      <div>
        <Typography noWrap variant="h5" fontWeight={"bold"}>
          Profile Information
        </Typography>
      </div>
      <Box sx={{ my: "30px" }}>
        <Typography fontSize="16px" sx={{ color: "var(--textColor)" }}>
          Account Email *
        </Typography>
        <Typography fontSize="18px" sx={{ mt: "5px" }}>
          taimoorjohn@hotmail.com
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formInputsFields}>
          <div>
            <InputCmp
              name="contactFirstName" //imporant
              control={control} //imporant
              label="Contact First Name"
            />
          </div>
          <div>
            <InputCmp
              name="contactLastName" //imporant
              control={control} //imporant
              label="Contact Last Name"
            />
          </div>
          <div>
            <InputCmp
              name="companyName" //imporant
              control={control} //imporant
              label="Company Name"
            />
          </div>
        </div>
        <section className={styles.formInputsFields}>
          <div>
            <InputCmp
              name="companyStreetAddress" //imporant
              control={control} //imporant
              label="Company Street Address"
            />
          </div>
          <div>
            <DropDownCmp
              name="companyState" //imporant
              control={control} //imporant
              label="Company State"
              options={statesdata}
              disableClearable
              freeSolo={false}
            />
          </div>
          <div>
            <DropDownCmp
              name="companyCity" //imporant
              control={control} //imporant
              label="Company City"
              options={citiesdata}
              disableClearable
              freeSolo={false}
            />
          </div>
        </section>

        <section className={styles.formInputsFields}>
          <div>
            <InputCmp
              name="companyZip" //imporant
              control={control} //imporant
              label="Company Zip"
            />
          </div>
          <div>
            <InputCmp
              name="yearEst" //imporant
              control={control} //imporant
              label="Year Est."
            />
          </div>
          <div>
            <InputCmp
              name="companyPhone" //imporant
              control={control} //imporant
              label="Company Phone"
            />
          </div>
        </section>
        <section className={styles.formInputsFields}>
          <div>
            <InputCmp
              name="companyEmailAddress" //imporant
              control={control} //imporant
              label="Company Email Address"
              type={"email"}
            />
          </div>
          {/* <div>
            <InputCmp
              name="website" //imporant
              control={control} //imporant
              label="Website"
              type={"url"}
            />
          </div> */}
          <div></div>
        </section>

        {/* ////btn */}
        <section className={styles.formBtns}>
          {/* <WhiteButtonCmp title="Cancel" /> */}
          <WhiteButtonCmp
            title="Skip Step"
            onClick={() => {
              setWhichForm("socialMedia");
            }}
          />
          <ButtonCmp
            title="Save and Continue"
            type="submit"
          // onClick={() => {
          //   onSubmit();
          // }}
          />
        </section>
      </form>
    </section>
  );
};

export default ProfileInfoForm;
