import React from "react";
import Button from "@mui/material/Button";

const ButtonCmp = ({ title, style, variant, btnColor, onClick, ...props }) => {
  return (
    <>
      <Button
        variant={variant ? variant : "contained"}
        color={btnColor ? "successBtn" : "buttonColor"}
        sx={{
          borderRadius: 10,
          fontSize: "12px",
          minWidth: "160px",
          padding: "13px 20px ",
          ...style,
        }}
        onClick={onClick}
        {...props}
      >
        {title ? title : "Button"}
      </Button>
    </>
  );
};

export default ButtonCmp;
