import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../Assets/Images/logo.png";
import BuildLinkLogo from "../../Assets/Images/BuildLinkCompleteLogo.png";
import BuildStudioLogo from "../../Assets/Images/BuildStudiologo-gold.png";
import Logo2 from "../../Assets/Images/logo2.png";
import { Stack, Typography } from "@mui/material";

import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
///icon

import styles from "./SideBar.module.css";
import {
	DashboardOutlinedIcon,
	Briefcase,
	BriefcasePlus,
	CheckBox,
	MultiCheckBox,
	TrashSvg,
	GavelSvg,
	ClockCheckSvg,
	WhishListSvg,
	DashboardSvg,
	ProfileSvg,
	ProConnectionSvg,
	NewConnectionSvg,
	ConnectionRequestsSvg,
	ChatSvg,
	EyeSvg,
} from "../../Assets";
import useGetUserRole from "../../Hooks/getUserRole";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";

//NEW SIDEBAR
const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const SideBarNav = ({
	handleDrawerToggle,
	icon,
	sideBarClose,
	setSideBarClose,
}) => {
	let navigate = useNavigate();
	const userRole = useGetUserRole();
	const location = useLocation();

	let renderSideBarOptions = () => {
		if (userRole == "buildlinks") {
			return <BuildLinkOptions sideBarClose={sideBarClose} />;
		} else if (userRole == "manufacturer") {
			return <ManufacturerOptions sideBarClose={sideBarClose} />;
		} else if (userRole == "supplier") {
			return <SupplierOptions sideBarClose={sideBarClose} />;
		} else if (userRole == "buildstudio") {
			return <BuildStudioOptions sideBarClose={sideBarClose} />;
		} else {
			return <BuildBidOptions sideBarClose={sideBarClose} />;
		}
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Drawer
				variant="permanent"
				open={sideBarClose}
			>
				{/* SIDEBAR BAR HEADER */}
				<SideBarHeader
					sideBarClose={sideBarClose}
					icon={icon}
					handleDrawerToggle={handleDrawerToggle}
				/>
				<Divider />

				{/* SIDEBAR OPTIONS */}
				{userRole == "buildlinks" ? (
					<BuildLinkOptions sideBarClose={sideBarClose} />
				) : userRole == "manufacturer" ? (
					<ManufacturerOptions sideBarClose={sideBarClose} />
				) : userRole == "supplier" ? (
					<SupplierOptions sideBarClose={sideBarClose} />
				) : userRole == "buildstudio" ? (
					<BuildStudioOptions sideBarClose={sideBarClose} />
				) : (
					<BuildBidOptions sideBarClose={sideBarClose} />
				)}
			</Drawer>
		</Box>
	);
};

const BuildBidOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();
	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<NavLink
				to="/dashboard"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					sx={{ margin: "10px !important" }}
					className={styles.listBtn}
				>
					<ListItemIcon>
						<DashboardSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Dashboard"
					/>
				</ListItemButton>
			</NavLink>
			<Divider />

			{/* /// Jobs Listing// */}

			<NavLink
				to="/jobs"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "20px !important" }}
				>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Jobs"
					/>
					{/* {open ? <ExpandLess /> : <ExpandMore />} */}
				</ListItemButton>
			</NavLink>
			{/* <Collapse in={open} timeout="auto" unmountOnExit> */}
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <BriefcasePlus />,
						text: "Create New Job",
						to: "createjob",
					},
					{
						icon: <CheckBox />,
						text: "Awarded Jobs",
						to: "awardedjob",
					},
					{
						icon: <MultiCheckBox />,
						text: "Completed Jobs",
						to: "completedjob",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						to: "trashjob",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>{list.icon}</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
			{/* </Collapse> */}

			{/* ///My Bids Listing// */}

			<NavLink
				to="/mybids"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<GavelSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="My Bids"
					/>
					{/* {open ? <ExpandLess /> : <ExpandMore />} */}
				</ListItemButton>
			</NavLink>
			{/* <Collapse in={open} timeout="auto" unmountOnExit> */}
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <ClockCheckSvg />,
						text: "Applied Bids",
						onClick: () => navigate("/"),
						to: "appliedprojects",
					},
					{
						icon: <CheckBox />,
						text: "Awarded Bids",
						onClick: () => navigate("/about"),
						to: "awardedproject",
					},
					{
						icon: <MultiCheckBox />,
						text: "Completed Bids",
						onClick: () => navigate("/"),
						to: "completedproject",
					},
					{
						icon: <WhishListSvg />,
						text: "Whish list",
						onClick: () => navigate("/"),
						to: "whishlist",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>{list.icon}</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
			{/* </Collapse> */}
		</List>
	);
};

const BuildLinkOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<NavLink
				to="//"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					sx={{ margin: "10px !important" }}
					className={styles.listBtn}
					onClick={() => navigate("/dashboard")}
				>
					<ListItemIcon>
						<DashboardSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Dashboard"
					/>
				</ListItemButton>
			</NavLink>
			<Divider />

			{/* /// Jobs Listing// */}

			<NavLink
				to="buildlinks/profile"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "20px !important" }}
				>
					<ListItemIcon>
						<ProfileSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Profile"
					/>
				</ListItemButton>
			</NavLink>

			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Pro Connection"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <NewConnectionSvg />,
						text: "Search Connection",
						onClick: () => navigate("/about"),
						to: "buildlinks/searchproconnection",
					},
					{
						icon: <ConnectionRequestsSvg />,
						text: "Pending Requests",
						onClick: () => navigate("/"),
						to: "buildlinks/pendingrequests",
					},
					{
						icon: <ConnectionRequestsSvg />,
						text: "Recevied Requests",
						onClick: () => navigate("/"),
						to: "buildlinks/receviedrequests",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>

			<NavLink
				to="/buildlinks/myprojectgallery"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="My Project Gallery"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <BriefcasePlus />,
						text: "Add New Project",
						onClick: () => navigate("/"),
						to: "buildlinks/addnewproject",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/"),
						to: "buildlinks/trashgallery",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
			<NavLink
				to="/buildlinks/chat"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ChatSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Chat"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
		</List>
	);
};

const InsuranceOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			<NavLink
				to="//"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					sx={{ margin: "10px !important" }}
					className={styles.listBtn}
					onClick={() => navigate("/dashboard")}
				>
					<ListItemIcon>
						<DashboardSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Dashboard"
					/>
				</ListItemButton>
			</NavLink>
			<Divider />

			{/* /// Jobs Listing// */}

			<NavLink
				to="buildlinks/profile"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "20px !important" }}
				>
					<ListItemIcon>
						<ProfileSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Profile"
					/>
				</ListItemButton>
			</NavLink>

			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Pro Connection"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <NewConnectionSvg />,
						text: "Search Connection",
						onClick: () => navigate("/about"),
						to: "buildlinks/searchproconnection",
					},
					{
						icon: <ConnectionRequestsSvg />,
						text: "Pending Requests",
						onClick: () => navigate("/"),
						to: "buildlinks/pendingrequests",
					},
					{
						icon: <ConnectionRequestsSvg />,
						text: "Recevied Requests",
						onClick: () => navigate("/"),
						to: "buildlinks/receviedrequests",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>

			<NavLink
				to="/buildlinks/myprojectgallery"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="My Project Gallery"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <BriefcasePlus />,
						text: "Add New Project",
						onClick: () => navigate("/"),
						to: "buildlinks/addnewproject",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/"),
						to: "buildlinks/trashgallery",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							key={index}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
			<NavLink
				to="/buildlinks/chat"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ChatSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Chat"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<NavLink
				to="/buildlinks/proconnection"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProConnectionSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
		</List>
	);
};

const ManufacturerOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* <NavLink
        to="//"
        className={({ isActive }) =>
          isActive ? styles.activeClassName : undefined
        }
      >
        <ListItemButton
          sx={{ margin: "10px !important" }}
          className={styles.listBtn}
        >
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText className={styles.list} primary="Dashboard" />
        </ListItemButton>
      </NavLink>
      <Divider /> */}

			{/* /// Jobs Listing// */}

			<NavLink
				to="/manufacturer/viewproduct"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "20px !important" }}
				>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Products"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					// {
					//   icon: <BriefcasePlus />,
					//   text: "View Products",
					//   // onClick: () => navigate("/createjob"),
					//   to: "/",
					// },
					// {
					//   icon: <EyeSvg />,
					//   text: "Edit Product",
					//   onClick: () => navigate("/about"),
					//   to: "/",
					// },
					{
						icon: <CheckBox />,
						text: "Add A New Product",
						onClick: () => navigate("/"),
						to: "manufacturer/addproduct",
					},
					{
						icon: <CheckBox />,
						text: "Import Products",
						onClick: () => navigate("/"),
						to: "manufacturer/importproduct",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/"),
						to: "manufacturer/trashedproducts",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>

			<NavLink to="manufacturer/orders/pending">
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Orders"
					/>
				</ListItemButton>
			</NavLink>
			<List>
				<NavLink
					to={`manufacturer/orders/pending`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Pending Orders"}
						/>
					</ListItemButton>
				</NavLink>

				<NavLink
					to={`manufacturer/orders/shipped`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Shipped Orders"}
						/>
					</ListItemButton>
				</NavLink>

				<NavLink
					to={`manufacturer/orders/delivered`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Delivered Orders"}
						/>
					</ListItemButton>
				</NavLink>

				<NavLink
					to={`manufacturer/orders/completed`}
					className={({ isActive }) =>
						isActive ? styles.activeClassName : undefined
					}
				>
					<ListItemButton
						className={styles.listBtn}
						sx={{ pl: sideBarClose ? 4 : 2 }}
						// onClick={list.onClick}
					>
						<ListItemIcon sx={{ minWidth: "37px" }}>
							<Briefcase />
						</ListItemIcon>
						<ListItemText
							className={styles.list}
							primary={"Completed Orders"}
						/>
					</ListItemButton>
				</NavLink>
			</List>
			<NavLink
				to="/manufacturer/profile"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProfileSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Profile"
					/>
				</ListItemButton>
			</NavLink>
		</List>
	);
};

const SupplierOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* <NavLink
        to="//"
        className={({ isActive }) =>
          isActive ? styles.activeClassName : undefined
        }
      >
        <ListItemButton
          sx={{ margin: "10px !important" }}
          className={styles.listBtn}
        >
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText className={styles.list} primary="Dashboard" />
        </ListItemButton>
      </NavLink>
      <Divider /> */}

			{/* /// Jobs Listing// */}

			<NavLink
				to="/supplier/viewproduct"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "20px !important" }}
				>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Products"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					// {
					//   icon: <BriefcasePlus />,
					//   text: "View Products",
					//   // onClick: () => navigate("/createjob"),
					//   to: "/",
					// },
					// {
					//   icon: <EyeSvg />,
					//   text: "Edit Product",
					//   onClick: () => navigate("/about"),
					//   to: "/",
					// },
					{
						icon: <CheckBox />,
						text: "Add A New Product",
						onClick: () => navigate("/"),
						to: "supplier/addproduct",
					},
					{
						icon: <CheckBox />,
						text: "Import Products",
						onClick: () => navigate("/"),
						to: "supplier/importproduct",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/"),
						to: "supplier/trashedproducts",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>

			<NavLink
				to="/supplier/profile"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton className={styles.listBtn}>
					<ListItemIcon>
						<ProfileSvg />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Profile"
					/>
				</ListItemButton>
			</NavLink>
		</List>
	);
};

const BuildStudioOptions = ({ sideBarClose }) => {
	let navigate = useNavigate();

	return (
		<List
			sx={{
				width: "100%",
				maxWidth: 360,
				bgcolor: "background.paper",
			}}
			component="nav"
			aria-labelledby="nested-list-subheader"
		>
			{/* <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive ? styles.activeClassName : undefined
        }
      >
        <ListItemButton
          sx={{ margin: "10px !important" }}
          className={styles.listBtn}
        >
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText className={styles.list} primary="Dashboard" />
        </ListItemButton>
      </NavLink>
      <Divider /> */}

			{/* /// Jobs Listing// */}

			<NavLink
				to="/buildstudio/jobs"
				className={({ isActive }) =>
					isActive ? styles.activeClassName : undefined
				}
			>
				<ListItemButton
					className={styles.listBtn}
					sx={{ mt: "20px !important" }}
				>
					<ListItemIcon>
						<Briefcase />
					</ListItemIcon>
					<ListItemText
						className={styles.list}
						primary="Jobs"
					/>
				</ListItemButton>
			</NavLink>
			<List
				component="div"
				disablePadding
			>
				{[
					{
						icon: <BriefcasePlus />,
						text: "Create New Project",
						// onClick: () => navigate("/createjob"),
						to: "buildstudio/createnewproject",
					},
					{
						icon: <TrashSvg />,
						text: "Trash",
						onClick: () => navigate("/about"),
						to: "buildstudio/trash",
					},
				].map((list, index) => (
					<NavLink
						to={`/${list.to}`}
						className={({ isActive }) =>
							isActive ? styles.activeClassName : undefined
						}
						key={index}
					>
						<ListItemButton
							className={styles.listBtn}
							sx={{ pl: sideBarClose ? 4 : 2 }}
							// onClick={list.onClick}
						>
							<ListItemIcon sx={{ minWidth: "37px" }}>
								<ListItemIcon sx={{ minWidth: "37px" }}>
									{list.icon}
								</ListItemIcon>
							</ListItemIcon>
							<ListItemText
								className={styles.list}
								primary={list.text}
							/>
						</ListItemButton>
					</NavLink>
				))}
			</List>
		</List>
	);
};

const SideBarHeader = ({ sideBarClose, icon, handleDrawerToggle }) => {
	const userRole = useGetUserRole();

	return (
		<Toolbar
			sx={{
				p: !sideBarClose ? "0 !important" : "auto",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			{icon && sideBarClose && (
				<Stack
					component="div"
					direction={"row"}
					justifyContent={"space-between"}
					alignItems={"center"}
					sx={{ width: "100%" }}
				>
					{userRole !== "manufacturer" &&
						userRole !== "buildlinks" &&
						userRole !== "buildstudio" && (
							<Box
								p={"8px"}
								borderRight={"1px solid var(--borderColor)"}
							>
								<img
									src={Logo2}
									alt="icon"
									className={styles.menuIcon}
								/>
							</Box>
						)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							gap: "10px",
							width: "100%",
							padding: "0 8px",
						}}
					>
						{userRole == "buildlinks" || userRole == "manufacturer" ? (
							<img
								src={BuildLinkLogo}
								alt="icon"
								className={styles.menuIcon}
								width={100}
								height={40}
								style={{
									objectFit: "cover",
								}}
							/>
						) : userRole == "buildstudio" ? (
							<img
								src={BuildStudioLogo}
								alt="icon"
								className={styles.menuIcon}
								width={100}
								height={40}
								style={{
									objectFit: "cover",
								}}
							/>
						) : (
							<img
								src={Logo}
								alt="icon"
								className={styles.menuIcon}
								// width={105}
								// height={40}
								// style={{
								//   objectFit: "contain",
								// }}
							/>
						)}
						<MenuOpenIcon
							onClick={() => handleDrawerToggle()}
							className={styles.menuIcon}
						/>
					</Box>
				</Stack>
			)}
			{!sideBarClose && (
				<Typography
					variant="h6"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					noWrap
					component="div"
				>
					{!sideBarClose && (
						<IconButton
							onClick={handleDrawerToggle}
							sx={{
								display: {
									xs: "none",
									lg: "inline-block",
								},
							}}
						>
							<MenuOpenIcon />
						</IconButton>
					)}
				</Typography>
			)}
		</Toolbar>
	);
};

export default SideBarNav;
