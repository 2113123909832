import {
  deleteMethodCustomHeader,
  getMethodCustomHeader,
  postMethodCustomHeader,
  postMethodMultiPartHeader,
  putMethodCustomHeader,
} from "../../Utils/response";
import { dateFormat } from "../../Utils/utils";
import ActionTypes from "../Constants";

const MyBidsProjectAction = (
  per_page,
  page,
  setAlert,
  searchParam,
  sortParam,
  locationParam,
  serviceParam
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_PROJECT_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `projects?per_page=${per_page}&page=${page}`
      // );
      const response = await getMethodCustomHeader(
        `projects?per_page=${per_page}&page=${page}&search=${
          searchParam || ""
        }&sortBy=${sortParam || ""}&location=${locationParam || ""}&service=${
          serviceParam || ""
        }`
      );
      console.log(response, "response");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_MYBIDS_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsGetProjectDetails = (slug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_PROJECT_DETAILS_REQUEST,
      });

      const response = await getMethodCustomHeader(`projects/${slug}`);
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_PROJECT_DETAILS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_PROJECT_DETAILS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log("error", error.response.data.message);
      dispatch({
        type: ActionTypes.GET_MYBIDS_PROJECT_DETAILS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsAddToWishList = (slug, data, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.MYBIDS_ADD_TO_WISHLIST_REQUEST,
      });
      const response = await postMethodCustomHeader(
        `user/projects/${slug}/wishlist`
      );

      ///success true
      if (response.data.success) {
        const responseMessage = response?.data?.message;
        let isWishList = false;
        if (responseMessage === "Project add to wishlist successfully") {
          isWishList = true;
        } else {
          isWishList = false;
        }
        dispatch({
          type: ActionTypes.MYBIDS_ADD_TO_WISHLIST_SUCCESS,
          payload: { isWishList, slug, data, page },
        });
      } else {
        dispatch({
          type: ActionTypes.MYBIDS_ADD_TO_WISHLIST_FAIL,
          payload: { addedToWishlist: false, slug, page },
        });
      }
    } catch (error) {
      ///
      // console.log(error.response.data.message, "errro");
      setAlert(error?.response?.data?.message, "error");
      console.log(error, "errro");
      dispatch({
        type: ActionTypes.MYBIDS_ADD_TO_WISHLIST_FAIL,
        payload: { addedToWishlist: false },
      });
    }
  };
};

const MyBidsCreateProposal = (data, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.MYBIDS_CREATE_PROPOSAL_REQUEST,
      });

      const { bid_amount, bid_duration, proposal_bid_note, pdf, services } =
        data;
      const objToSend = {
        bid_amount,
        bid_duration,
        proposal_bid_note,
      };

      const formData = new FormData();
      for (var key in objToSend) {
        formData.append(key, objToSend[key]);
      }

      for (var i = 0; i < pdf.length; i++) {
        formData.append(`pdf[${i}][pdf]`, pdf[i].file);
        formData.append(`pdf[${i}][title]`, pdf[i].title);
      }
      for (var i = 0; i < services.length; i++) {
        formData.append(`services[${i}]`, services[i]);
      }

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`, "form data values");
      // }

      const response = await postMethodMultiPartHeader(
        `projects/${data.slug}/bids`,
        formData
      );
      console.log("response MyBidsCreateProposal", response);
      ///success true
      if (response.data.success) {
        navigate("/appliedprojects");
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.MYBIDS_CREATE_PROPOSAL_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.MYBIDS_CREATE_PROPOSAL_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error.response.data.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.MYBIDS_CREATE_PROPOSAL_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsGetSubmittedBidDetails = (slug, bidId, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `projects/${slug}/bids/${bidId}`
      );
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log("error", error.response.data.message);
      dispatch({
        type: ActionTypes.GET_MYBIDS_SUBMITTED_BID_DETAILS_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsUpdateProposal = (data, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.MYBIDS_UPDATE_PROPOSAL_REQUEST,
      });

      const {
        bid_amount,
        bid_duration,
        proposal_bid_note,
        services,
        pdf,
        deletePDF,
      } = data;
      const objToSend = {
        bid_amount,
        bid_duration,
        proposal_bid_note,
      };

      const formData = new FormData();
      for (var key in objToSend) {
        formData.append(key, objToSend[key]);
      }

      for (var i = 0; i < services.length; i++) {
        formData.append(`services[${i}]`, services[i]);
      }
      for (var i = 0; i < pdf.length; i++) {
        formData.append(`pdf[${i}][pdf]`, pdf[i].file);
        formData.append(`pdf[${i}][title]`, pdf[i].title);
      }
      for (var i = 0; i < deletePDF.length; i++) {
        formData.append(`delete_pdf[${i}]`, deletePDF[i]);
      }

      // for (const pair of formData.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`, "form data values");
      // }

      const response = await postMethodMultiPartHeader(
        `projects/${data.slug}/bids`,
        formData
      );

      ///success true
      if (response.data.success) {
        navigate("/appliedprojects");
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.MYBIDS_UPDATE_PROPOSAL_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.MYBIDS_UPDATE_PROPOSAL_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error.response.data.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.MYBIDS_UPDATE_PROPOSAL_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsAwardedProjectsAction = (
  per_page,
  page,
  setAlert,
  searchParam,
  sortParam
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_AWARDED_PROJECT_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/projects-bid-awarded?per_page=${per_page}&page=${page}`
      // );

      const response = await getMethodCustomHeader(
        `user/projects-bid-awarded?per_page=${per_page}&page=${page}&search=${
          searchParam || ""
        }&sortBy=${sortParam || ""}`
      );

      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_AWARDED_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_AWARDED_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_MYBIDS_AWARDED_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsWishlistedProjectsAction = (per_page, page, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_REQUEST,
      });

      const response = await getMethodCustomHeader(
        `user/wishlists?per_page=${per_page}&page=${page}`
      );
      console.log(response, "response");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_MYBIDS_WISHLISTED_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsAppliedProjectsAction = (
  per_page,
  page,
  setAlert,
  searchParam,
  sortParam
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_APPLIED_PROJECT_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/projects-applied?per_page=${per_page}&page=${page}`
      // );
      const response = await getMethodCustomHeader(
        `user/projects-applied?per_page=${per_page}&page=${page}&search=${
          searchParam || ""
        }&sortBy=${sortParam || ""}`
      );
      console.log(response, "response");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_APPLIED_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_APPLIED_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      console.log(error, "MyBidsAppliedProjectsAction error");
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_MYBIDS_APPLIED_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const MyBidsCompletedProjectsAction = (
  per_page,
  page,
  setAlert,
  searchParam,
  sortParam
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_REQUEST,
      });

      // const response = await getMethodCustomHeader(
      //   `user/projects-bid-completed?per_page=${per_page}&page=${page}`
      // );
      const response = await getMethodCustomHeader(
        `user/projects-bid-completed?per_page=${per_page}&page=${page}&search=${
          searchParam || ""
        }&sortBy=${sortParam || ""}`
      );

      console.log(response, "response");
      ///success true
      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///
      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_MYBIDS_COMPLETED_PROJECT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const AddMileStoneAction = (projectSlug, setAlert, obj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.ADD_MILESTONE_REQUEST,
      });

      const servicesArray = obj.services.map((obj, ind) => {
        return {
          service: obj.slug,
        };
      });
      const objToSend = {
        amount_release_date: dateFormat(obj.completionDate),
        start_date: dateFormat(obj.jobStartDate),
        end_date: dateFormat(obj.completionDate),
        services: servicesArray,
        amount: obj.milestoneBudget,
      };

      const response = await postMethodCustomHeader(
        `user/projects/${projectSlug}/milestones`,
        objToSend
      );

      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");

        dispatch({
          type: ActionTypes.ADD_MILESTONE_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.ADD_MILESTONE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.ADD_MILESTONE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const DeleteMileStoneAction = (projectSlug, milestoneId, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.DELETE_MILESTONE_REQUEST,
      });

      const response = await deleteMethodCustomHeader(
        `user/projects/${projectSlug}/milestones/${milestoneId}`
      );

      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.DELETE_MILESTONE_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.DELETE_MILESTONE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.DELETE_MILESTONE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const EditMileStoneAction = (projectSlug, milestoneId, obj, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.EDIT_MILESTONE_REQUEST,
      });

      const servicesArray = obj.services.map((obj, ind) => {
        return {
          service: obj.slug,
        };
      });
      var statusValue = "";

      var objToSend = {};

      if (obj.jobStatus === "Active") {
        objToSend = {
          is_active: true,
        };
      } else if (obj.jobStatus === "Not Started") {
        objToSend = {
          amount_release_date: dateFormat(obj.completionDate),
          start_date: dateFormat(obj.jobStartDate),
          end_date: dateFormat(obj.completionDate),
          services: servicesArray,
          amount: obj.milestoneBudget,
          is_active: false,
          is_complete: false,
        };
      } else if (obj.jobStatus === "Completed") {
        objToSend = {
          is_complete: true,
          is_active: false,
        };
      }
      console.log("ObjTOSent", objToSend);
      const response = await putMethodCustomHeader(
        `user/projects/${projectSlug}/milestones/${milestoneId}`,
        objToSend
      );

      console.log("edit chala hai", response);

      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.EDIT_MILESTONE_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.EDIT_MILESTONE_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.EDIT_MILESTONE_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const EndContractAction = (projectSlug, setAlert) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.END_CONTRACT_REQUEST,
      });

      const objToSend = {
        is_complete: true,
      };
      console.log("objToSend", objToSend);
      const response = await putMethodCustomHeader(
        `user/proposal-action-complete/${projectSlug}`,
        objToSend
      );

      ///success true
      if (response.data.success) {
        setAlert(response.data.message, "success");
        dispatch({
          type: ActionTypes.END_CONTRACT_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.END_CONTRACT_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      ///

      setAlert(error?.response?.data?.message, "error");
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.END_CONTRACT_FAIL,
        payload: error.response.data,
      });
    }
  };
};

export {
  MyBidsProjectAction,
  MyBidsGetProjectDetails,
  MyBidsCreateProposal,
  MyBidsGetSubmittedBidDetails,
  MyBidsUpdateProposal,
  MyBidsAddToWishList,
  MyBidsWishlistedProjectsAction,
  MyBidsAppliedProjectsAction,
  MyBidsAwardedProjectsAction,
  MyBidsCompletedProjectsAction,
  AddMileStoneAction,
  DeleteMileStoneAction,
  EditMileStoneAction,
  EndContractAction,
};
