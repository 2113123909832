import React, { useEffect, useState } from "react";
import ProductTabPanel from "./ProductTabPanel";
import styles from "./AddProduct.module.css";
import { DropDownCmp } from "../../../Components";
import InputBox from "../../../Components/InputBox/InputBox";
import { useForm, Controller } from "react-hook-form";
import {
	GetColorsAction,
	GetMaterialsAction,
	GetCoatsAction,
	GetPrimersAction,
	GetDistributorsAction,
	GetSuppliersAction,
	GetFinishingsAction,
	GetPatternsAction,
	GetGlassTypesAction,
} from "../../../Store/Actions/GetActions";
import {
	Box,
	MenuItem,
	Checkbox,
	FormControlLabel,
	FormGroup,
	FormControl,
	InputLabel,
	Select,
	TextField,
	Button,
	Container,
	Grid,
	Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const ColorTab = (props) => {
	const { tabValue, control, watch, setValue, colorAppend, colorRemove } =
		props;
	const dispatch = useDispatch();

	const colors = watch("colors");
	const colorImages = [];

	const { colorsdata } = useSelector((state) => state.ColorsReducers);
	const { materialsdata } = useSelector((state) => state.MaterialsReducers);
	const { coatsdata } = useSelector((state) => state.CoatsReducers);
	const { primersdata } = useSelector((state) => state.PrimersReducers);
	const { finishingsdata } = useSelector((state) => state.FinishingsReducers);
	const { patternsdata } = useSelector((state) => state.PatternsReducers);
	const { glassTypesdata } = useSelector((state) => state.GlassTypesReducers);

	useEffect(() => {
		dispatch(GetColorsAction());
		dispatch(GetMaterialsAction());
		dispatch(GetCoatsAction());
		dispatch(GetPrimersAction());
		dispatch(GetDistributorsAction());
		dispatch(GetSuppliersAction());
		dispatch(GetFinishingsAction());
		dispatch(GetPatternsAction());
		dispatch(GetGlassTypesAction());
	}, []);

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	return (
		<ProductTabPanel
			value={tabValue}
			index={1}
		>
			<Grid
				container
				columnSpacing={2}
				rowSpacing={5}
			>
				<Grid
					item
					lg={6}
				>
					<FormControl
						sx={{ m: 1, width: "100%" }}
						variant="outlined"
					>
						<InputLabel id="colors-label">Select Finishes/Colors</InputLabel>
						<Controller
							name="colors"
							control={control}
							defaultValue={[]}
							render={({ field }) => (
								<Select
									labelId="colors-label"
									label="Select Finishes/Colors"
									multiple
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 300,
											},
										},
									}}
									value={field.value}
									onChange={(e) => {}}
									renderValue={(selected) =>
										selected
											.map(
												(x) =>
													colorsdata.find((y) => y.id === x.color_id)?.title
											)
											.join(" | ")
									}
								>
									{colorsdata.map((color) => (
										<MenuItem
											key={color.slug}
											value={color.slug}
											onClick={(e) => {
												let i = colors.findIndex((x) => x.color_id == color.id);
												if (i >= 0) {
													colorRemove(i);
												} else {
													colorAppend({
														color_id: color.id,
														color_title: color.title,
														color_code: color.code,
														color_slug: color.slug,
													});
												}
											}}
										>
											<Checkbox
												checked={
													-1 < colors?.findIndex((x) => x.color_id == color.id)
												}
											/>
											{color.title}
										</MenuItem>
									))}
									{/* Add more colors as needed */}
								</Select>
							)}
						/>
					</FormControl>
				</Grid>
			</Grid>
			{!colors ? (
				<Box sx={{ padding: "2rem" }}>
					<Typography>No colors selected</Typography>
				</Box>
			) : (
				colors?.map((color, index) => (
					<div key={color.color_id}>
						<Box
							sx={{
								marginTop: "1.8rem",
								marginBottom: "1.8rem",
								display: "flex",
								flexDirection: "row",
								flexWrap: "wrap",
							}}
							id={`${color.id}-label`}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Box sx={{ marginBottom: "1rem" }}>
									<span
										style={{
											width: "20px",
											height: "20px",
											backgroundColor: `${color.color_code}`,
											paddingLeft: "20px",
											paddingRight: "20px",
											borderRadius: "5px",
											marginRight: "20px",
										}}
									></span>
									<span>{`${color.color_title}`}</span>
								</Box>
								<Box>
									{colors[index].imageSrc && (
										<div>
											<img
												src={colors[index].imageSrc}
												alt="Thumbnail"
												style={{
													maxWidth: "200px",
													maxHeight: "200px",
												}}
											/>
										</div>
									)}
								</Box>
								<Box>
									<Controller
										name={`colors[${index}].image`}
										control={control}
										defaultValue=""
										render={({ field }) => (
											<>
												<input
													accept="image/*"
													style={{ display: "none" }}
													id={`colors-${index}-imgupload`}
													type="file"
													onChange={(e) => {
														const file = e.target.files[0];
														console.log(file);
														const reader = new FileReader();
														colorImages.push(watch(`colors[${index}].image`));
														colorImages.push(
															watch(`colors[${index}].imageSrc`)
														);
														setValue(`colors[${index}].image`, file);
														reader.onload = (event) => {
															setValue(
																`colors[${index}].imageSrc`,
																event.target.result
															);
															// Set the image data to the form field
														};

														reader.readAsDataURL(file);
													}}
												/>
												<label htmlFor={`colors-${index}-imgupload`}>
													<Button
														variant="contained"
														color="buttonColor"
														sx={{
															borderRadius: 10,
															fontSize: "12px",
															minWidth: "160px",
															padding: "13px 20px ",
														}}
														component="span"
													>
														Add/Edit Image
													</Button>
												</label>
											</>
										)}
									/>
								</Box>
							</Box>
						</Box>
						<FormGroup>
							<Grid
								container
								spacing={2}
								key={`${color.color_id}-input`}
							>
								{" "}
								{/* <Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="Finish"
										name={`colors[${index}].finish`}
										options={finishingsdata && finishingsdata}
										control={control}
										disableClearable
										freeSolo={false}
										multiple={true}
									/>
								</Grid> */}
								<Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="Material"
										name={`colors[${index}].material_id`}
										options={materialsdata && materialsdata}
										control={control}
										disableClearable
										freeSolo={false}
									/>
								</Grid>
								<Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="Pattern"
										name={`colors[${index}].pattern`}
										options={patternsdata && patternsdata}
										control={control}
										disableClearable
										freeSolo={false}
									/>
								</Grid>
								<Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="Primer"
										name={`colors[${index}].primer_id`}
										options={primersdata && primersdata}
										control={control}
										disableClearable
										freeSolo={false}
									/>
								</Grid>
								<Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="First Coat"
										name={`colors[${index}].first_coat`}
										options={coatsdata && coatsdata}
										control={control}
										disableClearable
										freeSolo={false}
									/>
								</Grid>
								<Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="Second Coat"
										name={`colors[${index}].second_coat`}
										options={coatsdata && coatsdata}
										control={control}
										disableClearable
										freeSolo={false}
									/>
								</Grid>
								<Grid
									item
									lg={3}
								>
									<DropDownCmp
										label="Glass Type"
										name={`colors[${index}].glass_type`}
										options={glassTypesdata && glassTypesdata}
										control={control}
										disableClearable
										freeSolo={false}
									/>
								</Grid>
								<Grid
									item
									lg={3}
								>
									<InputBox
										name={`colors[${index}].joint`}
										type="text"
										control={control}
										label="Joint"
									/>
								</Grid>
								{/* <Grid
									item
									lg={3}
								>
									<InputBox
										name={`colors[${index}].model_no`}
										type="text"
										control={control}
										label="Model No"
									/>
								</Grid> */}
							</Grid>
						</FormGroup>
					</div>
				))
			)}
		</ProductTabPanel>
	);
};

export default ColorTab;
