import { AlertCmp } from "../../Components";
import {
  getMethodCustomHeader,
  postMethodCustomHeader,
  postMethodWithoutToken,
} from "../../Utils/response";
import ActionTypes from "../Constants";

const ForgetAction = (obj, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_FORGET_REQUEST,
      });
      const response = await postMethodWithoutToken("forget", obj);
      console.log("response forget", response);
      if (response.data.success) {
        navigate("/otp-verification", {
          state: obj.email,
        });
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.USER_FORGET_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.USER_FORGET_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.USER_FORGET_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const CodeVerificationAction = (obj, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.CODE_VERIFICATION_REQUEST,
      });
      const response = await postMethodWithoutToken("reset-verify", obj);
      console.log("response CodeVerificationAction", response);
      if (response.data.success) {
        navigate("/confirmpassword", {
          state: obj.code,
        });
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.CODE_VERIFICATION_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.CODE_VERIFICATION_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.CODE_VERIFICATION_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const ConfirmPasswordAction = (obj, setAlert, navigate) => {
  return async (dispatch) => {
    console.log("confirmPasswordAction");
    try {
      dispatch({
        type: ActionTypes.USER_CONFIRMPASSWORD_REQUEST,
      });
      const response = await postMethodWithoutToken("reset", obj);
      console.log("response confirm password", response);
      if (response.data.success) {
        navigate("/login");
        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.USER_CONFIRMPASSWORD_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.USER_CONFIRMPASSWORD_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      setAlert(error.response?.data?.message, "error");
      dispatch({
        type: ActionTypes.USER_CONFIRMPASSWORD_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const LoginAction = (obj, setAlert, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_LOGIN_REQUEST,
      });
      const response = await postMethodWithoutToken("login", obj);
      if (response.data.success) {
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("userData", JSON.stringify(response.data.data));

        const userData = JSON.parse(localStorage.getItem("userData"))
        const shopUserData =  {
          company_name : userData.company_name,
          all_roles: userData.all_roles,
          name: userData.name,
          email: userData.email,
          company_email: userData.company_email,
          token:userData.token,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone: userData.phone,
          street_address: userData.street_address,
          zip: userData.zip
        }


        if(localStorage.getItem("from") == 'shop')
        {
          const str = `?data=${encodeURIComponent(JSON.stringify(shopUserData))}`
          window.location.href = `https://shop.buildlink.com/login${str}`
        }

        if (response.data.data.login_first_time) {
          if (response.data.data.type === "Manufacturer") {
            navigate("/manufacturerprofilecompletion");
          } 
          else if(response.data.data.type === "Supplier"){
            navigate("/supplierprofilecompletion");
          }
          else {
            navigate("/profilecompletion");
          }
        } else {
          console.log(response.data.data.type, "response.data.data?.type");
          ///if user is Manufacturer navigate to Manufacturer profile module
          if (response.data.data.type === "Manufacturer") {
            navigate("/manufacturer/profile");
          } else if(response.data.data.type === "Supplier") {
            navigate("/supplier/profile");
          }
          else {
            navigate("/buildlinks/profile");
          }
        }

        setAlert(response.data?.message, "success");
        dispatch({
          type: ActionTypes.USER_LOGIN_SUCCESS,
          payload: response.data,
        });
      } else {
        setAlert(response.data?.message, "error");
        dispatch({
          type: ActionTypes.USER_LOGIN_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      //   console.log(error.response.data.message, "errro");

      setAlert(error.response?.data?.message, "error");

      dispatch({
        type: ActionTypes.USER_LOGIN_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const SignUpAction = (obj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_SIGNUP_REQUEST,
      });

      const objToSend = {
        first_name: obj.firstName,
        last_name: obj.lastName,
        email: obj.email,
        type: obj.userType,
        password: obj.password,
        password_confirmation: obj.confirmPassword,
      };

      console.log("obj action", objToSend);
      const response = await postMethodWithoutToken("register", objToSend);
      console.log("response signup", response);
      if (response.data.success) {
        dispatch({
          type: ActionTypes.USER_SIGNUP_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.USER_SIGNUP_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.USER_SIGNUP_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const LogoutAction = (navigate) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_LOGOUT_REQUEST,
      });
      const response = await postMethodCustomHeader("logout");
      if (response.data.success) {
        localStorage.removeItem("userData");
        localStorage.removeItem("token");
        navigate("/login");
        dispatch({
          type: ActionTypes.USER_LOGOUT_SUCCESS,
        });
      } else {
        dispatch({
          type: ActionTypes.USER_SIGNUP_FAIL,
        });
      }
    } catch (error) {
      dispatch({
        type: ActionTypes.USER_LOGOUT_FAIL,
      });
    }
  };
};

const UserRoleAction = (role) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.GET_USER_ROLE,
      payload: role,
    });
  };
};

const GetUserAction = (obj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_SIGNUP_REQUEST,
      });

      const response = await getMethodCustomHeader("user");
      console.log("GetUserAction", response);
      if (response.data.success) {
        dispatch({
          type: ActionTypes.USER_SIGNUP_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.USER_SIGNUP_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.USER_SIGNUP_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const UpdateDeviceID = (obj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.UPDATE_DEVICEID_REQUEST,
      });

      const response = await postMethodCustomHeader("store-device", obj);
      console.log("UpdateDeviceID", response);
      if (response.data.success) {
        dispatch({
          type: ActionTypes.UPDATE_DEVICEID_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_DEVICEID_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.UPDATE_DEVICEID_FAIL,
        payload: error.response.data,
      });
    }
  };
};

const GetAllUserAction = (obj) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.GET_ALL_USER_REQUEST,
      });
      const response = await getMethodCustomHeader("users");

      if (response.data.success) {
        dispatch({
          type: ActionTypes.GET_ALL_USER_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_ALL_USER_FAIL,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error.response.data.message, "errro");
      dispatch({
        type: ActionTypes.GET_ALL_USER_FAIL,
        payload: error.response.data,
      });
    }
  };
};

export {
  LoginAction,
  SignUpAction,
  UserRoleAction,
  LogoutAction,
  GetUserAction,
  UpdateDeviceID,
  ForgetAction,
  ConfirmPasswordAction,
  CodeVerificationAction,
  GetAllUserAction,
};
