import { Box, Rating, Typography } from "@mui/material";
import { RatingCmp } from "..";

import {
  ChatIcon,
  greenCheck,
  LocationOnOutlinedIcon,
  MessageOutlinedIcon,
  phoneIcon,
  WatchLaterOutlinedIcon,
} from "../../Assets";
import styles from "./AwardedProjectCard.module.css";
import { useNavigate } from "react-router-dom";

const AwardedJobCard = ({
  projectFB,
  companyFB,
  myBidsAwardedProjectdata,
  onClick,
}) => {
  const {
    address,
    zipcode,
    label,
    user,
    job_awarded_date,
    job_completion_date,
    job_start_date,
  } = myBidsAwardedProjectdata.project;

  const navigate = useNavigate();
  const sendMessage = () => {
    console.log("sendMessage");
    navigate("/buildlinks/chat", {
      state: {
        userLocation: user && user,
      },
    });
  };

  return (
    <section className={styles.card_wrapper} onClick={onClick}>
      <section className={styles.project_wrappper}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold" }}
              className={styles.bidName}
            >
              {/* Wood House Constuction */}
              {label}
            </Typography>
          </Box>
          <Box sx={{ margin: "5px 0 " }}>
            <Typography
              sx={{
                fontSize: "small",
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnOutlinedIcon
                fontSize="small"
                sx={{ color: "var(--primary-color)", marginLeft: "-5px" }}
              />
              {/* 312 Main Street, New Orleans LA, 70115 */}
              {address}, {zipcode}
            </Typography>
          </Box>
        </Box>

        <Box className={styles.projectDate}>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Start Date:
            </Typography>
            <Typography fontSize="14px">{job_start_date}</Typography>
          </section>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Completition Date:
            </Typography>
            <Typography fontSize="14px">{job_completion_date}</Typography>
          </section>
        </Box>
      </section>

      {/* ///company details */}
      <section className={styles.company_wrappper}>
        <Box
          sx={{ display: " flex", justifyContent: "space-between" }}
          className={styles.companyName}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {/* Owais Joel Company */}
                {user?.company_name ? user?.company_name : user?.label}
              </Typography>
            </Box>
            <Box sx={{ margin: "5px 0 " }}>
              <Typography
                sx={{
                  fontSize: "small",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlinedIcon
                  fontSize="small"
                  sx={{ color: "var(--primary-color)", marginLeft: "-5px" }}
                />
                {/* 312 Main Street, New Orleans LA, 70115 */}
                {`${user.street_address}, ${user.zip}`}
              </Typography>
            </Box>
          </Box>
          <div>
            <img src={greenCheck} alt="" />
          </div>
        </Box>

        <div className={styles.projectDate}>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Job Awarded Date:
            </Typography>
            <Typography fontSize="14px">{job_awarded_date}</Typography>
          </section>
          <section>
            <Typography fontSize="small" sx={{ color: "var(--textColor)" }}>
              Contact Details:
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography
                fontSize="14px"
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <img src={phoneIcon} />
                {/* +1 123 123 1234 */}
                {`${user?.phone}`}
              </Typography>
              <Typography
                fontSize="small"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  sendMessage();
                }}
              >
                <img src={ChatIcon} />
                Message
              </Typography>
            </Box>
          </section>
        </div>
      </section>
    </section>
  );
};

export default AwardedJobCard;
